<template>
  <transition name="expand" :appear="appear" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <slot />
  </transition>
</template>

<script setup lang="ts">
import type { RendererElement } from 'vue';

defineComponent({
  name: 'ShareButton',
});
defineProps({
  appear: {
    type: Boolean,
    default: false,
  },
});

function enter(element: RendererElement) {
  const width = getComputedStyle(element as Element).width;

  element.style.width = width;
  element.style.position = 'absolute';
  element.style.visibility = 'hidden';
  element.style.height = 'auto';

  requestAnimationFrame(() => {
    const height = getComputedStyle(element as Element).height;

    element.style.width = null;
    element.style.position = null;
    element.style.visibility = null;
    element.style.height = 0;

    requestAnimationFrame(() => {
      element.style.height = height;
    });
  });
}

function afterEnter(element: RendererElement) {
  element.style.height = 'auto';
}

function leave(element: RendererElement) {
  const height = getComputedStyle(element as Element).height;

  element.style.height = height;

  // Force repaint to make sure the
  // animation is triggered correctly.
  // eslint-disable-next-line no-unused-expressions
  getComputedStyle(element as Element).height;

  setTimeout(() => {
    element.style.height = 0;
  });
}
</script>

<style lang="postcss" scoped>
.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
