<template>
  <div>
    <LoadingProductsListPage v-if="isFetching && !appliedFacets.length" class="[ container mx-auto ]" />
    <div v-else>
      <section v-if="showListingView" class="ProductListing__inner">
        <div class="hidden px-5 lg:px-0 md:flex flex-col">
          <section v-if="facets.length" class="hidden md:flex md:mb-7 md:justify-between items-center">
            <h2 class="text-base font-bold">
              {{ $t('filterProducts') }}
            </h2>

            <SortProductsDropdown v-model="sortBy" class="inline-block" />
          </section>

          <div data-cy-region="filters" class="grid FacetsContainer gap-x-5 mb-3">
            <div class="Facets">
              <FacetDropdown
                v-for="(facet, idx) in appliedFacets.filter(
                  facet => enabledFacet(facet, renderIncludedFacets) && !disabledFacet(facet, renderExcludedFacets),
                )"
                :key="idx"
                :value="facet.value"
                :options="facet.options"
                :label="facet.label"
                :type="facet.type"
                @input="($event: AggregationOption[]) => updateFacetWithSelectedValue($event, facet)"
              />
            </div>
          </div>
        </div>

        <div class="ProductListing__products px-5 lg:px-0">
          <slot />
          <div class="flex flex-col lg:flex-row lg:items-center overflow-hidden lg:overflow-visible md:mb-7">
            <div class="mb-7 flex items-center justify-between ml-auto w-full">
              <h3 v-if="totalCount" class="text-primary-1-40 text-sm md:hidden uppercase font-body">
                {{ $t('products', { totalCount: totalCount }, totalCount) }}
              </h3>

              <button
                class="lg:hidden flex items-center justify-center capitalize"
                type="button"
                :aria-label="$t('filterProducts')"
                @click="isFiltering = true"
              >
                <SvgIconFilters width="24" height="24" />
              </button>
            </div>
          </div>

          <FacetsTagsList :facets="appliedFacets" class="gap-y-2 mb-6" @click="handleRemoveItem" />

          <template v-if="products">
            <h5 v-if="totalCount" class="hidden lg:flex text-sm text-primary-1-40">
              {{ $t('products', { totalCount: totalCount }, totalCount) }}
            </h5>

            <div data-cy-region="product-listing" class="mt-5 lg:mt-7 ProductsGrid">
              <ProductCard v-for="product in products" :key="product.id" :product="product" />
            </div>
          </template>
          <LoadingProductsList v-if="isFetching" />
          <slot name="empty">
            <div
              v-if="!isFetching && totalCount === 0"
              class="flex flex-col items-center lg:max-w-lg lg:mx-auto mt-12 lg:mt-16"
            >
              <span class="flex items-center justify-center w-20 h-20 rounded-full bg-page-secondary mb-4">
                <SvgIconNotFoundSearch width="40" height="40" class="fill-current text-brown-600" />
              </span>

              <h1 class="text-base capitalize font-body font-medium text-primary-1-100">
                {{ $t('notFoundTitle') }}
              </h1>

              <p class="text-primary-1-60 text-sm text-center mt-2 mb-6">
                {{ $t('notFoundTip') }}
              </p>
            </div>
          </slot>

          <PageInfo
            v-if="totalCount"
            class="mt-15"
            :is-fetching="isFetching"
            :total-count="totalCount"
            :current-count="products.length"
            @next="nextPage"
          />
        </div>

        <FacetDialog
          v-model:is-open="isFiltering"
          :value="
            appliedFacets.filter(
              facet => enabledFacet(facet, renderIncludedFacets) && !disabledFacet(facet, renderExcludedFacets),
            )
          "
          :with-sorting="type !== 'search'"
          @sort="(e: typeof sortBy) => (sortBy = e)"
          @apply="updateFacetDialogWithSelectedValue($event)"
        />
      </section>
      <section v-else>
        <slot name="emptyReplacement" />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  ProductListingType as ListingType,
  MappedAggregation,
  ProductsGenericVariables,
  ProductsQuery,
} from '@robustastudio/e-commerce/common';
import { merge } from 'lodash-es';
import type { AggregationOption, ProductAttributeFilterInput } from '~/graphql-types.gen';

type ProductListingType = ListingType | 'isNew';

const { t: $t } = useI18n({
  useScope: 'local',
});

function disabledFacet(facet: MappedAggregation, excluded?: string[]): boolean {
  if (!excluded?.length) {
    return false;
  }

  return excluded?.includes(facet.code);
}

function enabledFacet(facet: MappedAggregation, included?: string[]): boolean {
  // If no preferable facets, then return them all

  return !included?.length ? true : included.includes(facet.code);
}

const data = ref<ProductsQuery | null>(null);

const options = {
  enableFacetTags: true,
};

const emit = defineEmits<{
  (event: 'update:facets', facets: MappedAggregation[]): void;
  (event: 'categories', categories: MappedAggregation[]): void;
  (event: 'fetching', fetching: boolean): void;
}>();

const props = defineProps({
  /*
   * The type of product listing to display (default , discounted or search).
   * @property type
   * @type ProductListingType
   * @public
   * @required
   * @default ProductListingType.default
   */
  type: {
    type: String as PropType<ProductListingType>,
    default: 'default',
    required: false,
    validator: (value: string) => ['default', 'discounted', 'search', 'isNew'].includes(value),
  },

  facets: {
    type: Array as PropType<MappedAggregation[]>,
    default: () => [],
    required: false,
  },
  /**
   * contains the search phrase that was used to filter the products
   * in case of a search listing
   */
  search: {
    type: String,
    default: '',
    required: false,
  },
  /**
   * contains the brand used to filter the products
   * in case of a brand listing
   */
  brandId: {
    type: [Number, String],
    default: '',
    required: false,
  },
  /**
   * contains the category used to filter the products
   * in case of a category listing
   */
  categoryId: {
    type: [Number, String],
    default: '',
    required: false,
  },
  /**
   * Contains the email of the seller
   */
  seller: {
    type: String,
    default: '',
    required: false,
  },
  /**
   *
   * contains a list of excluded facets from being displayed in the facet dialog or facet dropdown
   */
  renderExcludedFacets: {
    type: Array as PropType<string[]>,
    default: () => [],
    required: false,
  },
  /**
   *
   * contains a list of Preferable facets that will be displayed in the facet dialog or facet dropdown
   * *used only during rendering the options of the facet dropdown
   */
  renderIncludedFacets: {
    type: Array as PropType<string[]>,
    default: () => [],
    required: false,
  },
  /**
   *
   * contains a list of Preferable facets that should be populated to the the component when it is mounted
   * ! this is so important in case of there is a large list of facets
   */
  includedFacets: {
    type: Array as PropType<string[]>,
    default: () => [],
    required: false,
  },

  /**
   *
   * filters
   */
  filters: {
    type: Object as PropType<ProductAttributeFilterInput>,
    default: () => ({}),
  },
});

const isFiltering = ref(false);
/**
 * Edit page size according to screen size
 * mobile: number must be divisble by 2,
 * desktop: number must be divisble by 5
 */
const isMobileScreenSize = computed(() => {
  if (import.meta.client) {
    return window.innerWidth < 768;
  }

  return false;
});
const { nextPage, currentPage, pageSize, updatePageInfo } = usePagination({
  pageSize: isMobileScreenSize.value ? 12 : 15,
});

const aggregations = computed(() => {
  return data.value?.connection?.aggregations;
});

const {
  facets: appliedFacets,
  categories,
  filters: facetsFilters,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  urlQueryPrams: filtersQueryParams,

  removeItem,
} = useFacets(aggregations, {
  exclude: ['is_featured_offer', 'is_featured', 'product_offer_type', 'is_gift_only'],
  only: props.includedFacets,
});

const { selectedOption: sortBy, defaultSortOption } = useSortBy();

const variables = computed<Partial<ProductsGenericVariables>>(() => {
  return {
    page: currentPage.value,
    pageSize: pageSize.value,
    /**
     * disable sorting in case of search
     */
    ...resolveSortOptions.value,
    filter: computedFilters.value,
    withAggregations: true,
    withPaging: true,
    search: props.search,
  };
});

const resolveSortOptions = computed(() => {
  return props.type === 'search' ? {} : { sort: sortBy.value?.value || defaultSortOption };
});

/**
 * a computed watcher for all filters captured from the app context ( props , url , selected options ,or component props )
 *
 * lowest priority goes to the filters captured normally from filter options controllers and url parameters
 * then the container listing hardcoded props like a specific definition like ( specific category , specific brand, ...etc)
 * !this one should be used in a limited and so narrow conditions ,in order not to break the facets selectors functionality or the specific purpose of the listing itself
 * then the hard coded props filters to append other previous values ,
 *
 *
 */
const computedFilters = computed(() =>
  merge(
    facetsFilters.value,
    props.categoryId ? { category_id: { eq: props.categoryId + '' || '' } } : {},
    props.brandId ? { product_brand_id: { eq: props.brandId + '' || '' } } : {},
    props.filters,
  ),
);

const {
  products,
  totalCount,
  data: productData,
  isFetching,
} = useProductsGeneric(variables, props.type === 'discounted' ? 'discounted' : 'default', {
  newIn: props.type === 'isNew' ? 160 : 0,
});

/**
 * Syncing fetching product by emitting the is fetching event
 */
watch(
  isFetching,
  value => {
    emit('fetching', !!(value && appliedFacets.value?.length));
  },
  {
    immediate: true,
  },
);

/**
 * controls the display of the facet tags when a filter is applied
 */
const displayFacetTags = computed(
  () =>
    (options.enableFacetTags &&
      appliedFacets.value.filter(
        facet => isFilterableFacet(facet) && enabledFacet(facet, props.renderIncludedFacets ?? []),
      )?.length) ||
    0,
);

const slots = useSlots();

/**
 * if we dont have a replacement empty view
 * or we are currently in the filtering process
 * or we have an empty replacement view, we are not currently filtering and we have no products to display
 */
const showListingView = computed(() => {
  return (
    !slots.emptyReplacement ||
    !!displayFacetTags.value ||
    (!!slots.emptyReplacement && !displayFacetTags.value && totalCount.value > 0)
  );
});

/**
 * called on selected a facet from the facet dropdowns
 * handles updating the current facet with the new selected value
 */
const updateFacetWithSelectedValue = (value: AggregationOption[], facet: MappedAggregation) => {
  currentPage.value = 1;
  nextTick(() => (facet.value = value));
};

/**
 * Same as updateFacetWithSelectedValue but for the facet dialog as it accepts a MappedAggregation[]
 */
const updateFacetDialogWithSelectedValue = (facets: MappedAggregation[]) => {
  currentPage.value = 1;
  nextTick(() => (appliedFacets.value = facets));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const showFacetTags = computed(() => {
  return computedFilters.value && !!Object.keys(computedFilters.value).length;
});

watch(productData, value => {
  updatePageInfo(value?.connection?.page_info);
  data.value = value;
});

/*
 * called on filter option change dropdown change
 * emits the new filter options to the parent component
 * @param {AggregationOption[]} value
 * */
watch(appliedFacets, value => {
  emit('update:facets', value);
});

watch(categories, value => {
  emit('categories', value);
});

/*
 * called on filter option change dropdown change
 * emits the new filter options to the parent component
 * @param {AggregationOption[]} value
 * */
watch(
  () => props.facets,
  value => {
    if (!value) return;
    appliedFacets.value = value;
  },
);

/**
 * Resetting page number when sorting is change
 *
 */
watch(sortBy, () => {
  currentPage.value = 1;
});

function handleRemoveItem(e: any) {
  currentPage.value = 1;
  nextTick(() => removeItem(e));
}
</script>

<style lang="postcss" scoped>
.ProductListing {
  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    @screen lg {
      grid-template-columns: 1fr;
      column-gap: 60px;
    }
  }
}

.Facets {
  @apply grid grid-cols-4 gap-5;
}
</style>

<i18n>
{
  "en": {
    "notFoundTitle": "No Products Found",
    "notFoundTip": "We couldn't find any products that match your selected filters. Please try adjusting your filters or exploring our other products.",
    "products": "{totalCount} Product | {totalCount} Products",
    "filterBy": "Filter by",
    "noResults": "No results",
    "filterProducts": "Filter Products",
    "clearAll": "Clear Filters",
    "shoppingGuide": "Shopping Guide",
    "checkGuide": "Check Guide",
    "info": "We can help you find the perfect fit",
    "filter": "FILTER",
    "filters": "Filters",
    "enhanceNote": "Enhance your search results with personalization"
  },
  "ar": {
    "notFoundTitle": "لم يتم العثور على منتجات متطابقة",
    "notFoundTip": "لم نتمكن من العثور على أي منتجات تتوافق مع عوامل التصفية التي اخترتها. يرجى محاولة تعديل عوامل التصفية الخاصة بك أو استكشاف منتجاتنا الأخرى.",
    "products": "{totalCount} منتج",
    "filterBy": "تسوق حسب",
    "noResults": "لا يوجد نتائج",
    "filterProducts": "تصفية المنتجات",
    "clearAll": "مسح الكل",
    "shoppingGuide": "دليل التسوق",
    "checkGuide": "دليل التحقق",
    "info": "يمكننا مساعدتك على العثور على المنتج المحدد",
    "filter": "ترشيح",
    "filters": "ترشيح",
    "enhanceNote": "تحسين نتائج البحث مع التخصيص"
  }
}
</i18n>
