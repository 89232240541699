<template>
  <div class="SortProducts bg-primary-1-05 rounded-xl" :class="{ 'is-open': isOpen }">
    <button
      class="SortProducts__toggle border rounded-xl border-primary-1-10 py-2 px-4 text-base"
      type="button"
      :aria-label="$t('sortDropdown', { option: selectedOption?.label || 'none' })"
      @click="toggle"
    >
      <span class="text-primary-1-100"> {{ $t('sortBy') }} .</span>
      <span class="ml-2 mr-6 text-primary-1-100 font-bold">{{ $t(selectedOption?.label as string) || '' }}</span>

      <svg-icon-chevron-down class="ml-auto h-4 w-4 SortProducts__toggle-caret" />
    </button>

    <transition name="fade">
      <GenericMultiselect
        v-if="isOpen"
        v-model="selectedOption"
        :options="options"
        single
        immediate
        has-translation
        @input="(e: typeof selectedOption) => (selectedOption = e)"
        @close="toggle(false)"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});

const { isOpen, toggle } = useDropdown();
const { options, selectedOption } = useSortBy();
</script>

<style lang="postcss" scoped>
.SortProducts {
  @apply relative;
  min-width: 200px;

  &__toggle {
    @apply flex items-center  text-sm;
    width: 300px;
    &-caret {
      transition: transform 0.2s ease-in-out;
    }
  }

  &.is-open {
    .SortProducts__toggle-caret {
      transform: rotate(180deg);
    }
  }

  &__MenuItems {
    @apply absolute left-0 right-0 z-20 flex flex-col bg-white;
    top: calc(100% - 2px);
    box-shadow: 0 0 20px 0 rgba(144, 144, 144, 0.15);

    li:not(:first-child) {
      @apply border-t border-primary-200 pt-3;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<i18n>
{
  "en": {
    "sortBy": "Sort by",
    "mostPopular": "Most Popular",
    "priceLowToHigh": "By Price From Low To High",
    "priceHighToLow": "By Price From High To Low",
    "sortDropdown":"sort by options dropdown: {option}"
  },
  "ar": {
    "sortBy": "ترتيب",
    "mostPopular": "الأكثر شيوعا",
    "priceLowToHigh": "حسب السعر من الاقل للأكبر",
    "priceHighToLow": "حسب السعر من الاكبر للأقل",
    "sortDropdown": "اختر الترتيب طبقا ل {option}"
  }
}
</i18n>
