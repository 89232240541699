<template>
  <button
    type="button"
    class="flex items-center text-black text-sm rounded-xl font-semibold py-2 px-5 facet-items border bg-primary-1-05 border-primary-1-10"
  >
    <span>{{ price }}</span>
    <svg-icon-close class="text-left text-black cursor-pointer ml-4" width="10" height="10" />
  </button>
</template>

<script setup lang="ts">
defineComponent({
  name: 'FacetTagPrice',
});

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'select',
  },
  code: {
    type: String,
    default: '',
  },
});

const currencyCode = inject(CONFIG_STORE_CURRENCY);

const price = computed(() => {
  return `${props.value.split('-')[0]} ${currencyCode?.value ?? 'EGP'} - ${props.value.split('-')[1]} ${
    currencyCode?.value ?? 'EGP'
  }`;
});
</script>

<style lang="postcss" scoped></style>
