<template>
  <div v-if="type !== 'hidden'" class="FacetDropdown" :class="{ 'is-open': isOpen }">
    <span v-if="type !== 'toggle'" class="text-primary-1-100 text-sm">{{ te(label) ? $t(label) : label }}</span>
    <div v-if="type === 'toggle'" class="flex items-center justify-between h-full w-full">
      <span class="text-primary-1-100 text-sm">{{ label }}</span>
      <ToggleInput
        :model-value="Array.isArray(value) && value.length ? value : []"
        :true-value="(options as AggregationOption[])?.find((option: AggregationOption) => option.value === '1')"
        :false-value="[]"
        @input="($event: AggregationOption) => updateFacet($event)"
      />
    </div>

    <button
      v-else
      class="FacetDropdown__toggle"
      type="button"
      :aria-label="`filter ${label} dropdown filter`"
      :aria-expanded="isOpen"
      @click="toggle"
    >
      <span v-if="selectedLabel" class="ml-2 text-primary-1-40 text-sm truncate">{{ selectedLabel }}</span>
      <span v-else class="text-sm text-primary-1-40 truncate">
        {{ $t('select') }} {{ `${te(label) ? $t(label) : label}` }}</span
      >

      <svg-icon-chevron-down class="ml-auto h-4 w-4 FacetDropdown__toggle-caret" />
    </button>

    <transition-group name="fade">
      <template v-if="isOpen">
        <div v-if="isSelectProps(props)">
          <GenericMultiselect
            v-if="isSelectInput"
            :model-value="selectedOptions"
            :options="props.options"
            :immediate="true"
            identified-by="value"
            @close="toggle(false)"
            @input="updateFacet"
          />
        </div>
        <PriceFacet
          v-if="isPriceProps(props)"
          :value="[props.value[0], props.value[1]]"
          :options="[props.options[0], props.options[props.options.length - 1]]"
          @input="updateFacet"
        />
      </template>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import type { AggregationOption, FacetType } from '@robustastudio/e-commerce/common';

interface IProps {
  label: string;
}

interface ISelectProps extends IProps {
  value: AggregationOption[] | null | undefined;
  options: AggregationOption[];
  type: FacetType.Select | FacetType.Toggle;
}

interface IRadioSelectProps extends IProps {
  value: AggregationOption | null | undefined;
  options: AggregationOption[];
  type: FacetType.Select | FacetType.Toggle;
}

interface IPriceProps extends IProps {
  value: number[];
  options: number[];
  type: FacetType.Price;
}

interface IToggleProps extends IProps {
  value: AggregationOption[] | null | undefined;
  options: never;
  type: FacetType.Toggle;
}

interface IHiddenProps extends IProps {
  type: FacetType.Hidden;
  options: never;
  value: never;
}

type Props = ISelectProps | IPriceProps | IHiddenProps | IToggleProps | IRadioSelectProps;

const props = defineProps<Props>();
const isSelectInput = props.type === 'select';

if (props.type === 'select') {
  props.options;
}

const isSelectProps = (props: Props): props is ISelectProps => props.type === 'select';

const isPriceProps = (props: Props): props is IPriceProps => props.type === 'price';

const emit = defineEmits(['input']);

const currencyCode = inject(CONFIG_STORE_CURRENCY);
const { isOpen, toggle } = useDropdown();

const { t: $t, te } = useI18n({
  useScope: 'local',
});
const selectedOptions = computed(() =>
  Array.isArray(props.value) ? props.value : [props.value].filter(val => val ?? false),
);

const selectedLabel = computed(() => {
  if (isSelectProps(props)) {
    return props.value?.map(curr => curr?.label).join(',');
  }

  if (isPriceProps(props) && props.value.length) {
    return `${props.value[0]} ${currencyCode?.value} - ${props.value[1]} ${currencyCode?.value} `;
  }

  return '';
});

/** * called when a new facet option is selected / deselected * handles emitting the new values to the parent component
(category page) */
function updateFacet(newValue: AggregationOption) {
  emit('input', Array.isArray(newValue) ? newValue : [newValue]);
}
</script>

<style lang="postcss" scoped>
.FacetDropdown {
  @apply relative;

  &__toggle {
    @apply w-full flex items-center border-b border-primary-1-10 py-2 text-base;

    &-caret {
      transition: transform 0.2s ease-in-out;
    }
  }

  &.is-open {
    .FacetDropdown__toggle {
      @apply border-none;
    }
    .FacetDropdown__toggle-caret {
      transform: rotate(180deg);
    }
  }

  :deep(.PriceFacet) {
    @apply absolute left-0 z-20 px-5 bg-primary-1-05 border rounded-xl border-primary-1-10;
    top: calc(100% - 2px);
    min-width: 288px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<i18n>
{
  "en": {
    "select": "Choose ",
    "price": "Price"
  },
  "ar": {
    "select": "أختر",
    "price": "السعر"
  }
}
</i18n>
